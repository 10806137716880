import SocialIcons from 'components/SocialIcons'
import { Link } from 'gatsby'
import Container from 'layouts/Container'
import React from 'react'
import styled, { css } from 'styled-components'
import { cvar, fwvar, mediaQueries, svar } from 'styles'
import Headline from '../components/Headline/index'

export default function NotFoundPage() {
  return (
    <Wrapper>
      <Container styles={containerStyles}>
        <Headline align="center">
          Oops! Looks like that page doesn't exist...
        </Headline>
        <Text>
          You were probably looking to get your
          <br />
          <GatsbyLink to="/#contact">FREE Tech Report</GatsbyLink>
        </Text>
        <GatsbyLink to="/">Go back to homepage</GatsbyLink>
        <SocialIcons color={cvar('primary')} />
      </Container>
    </Wrapper>
  )
}

const containerStyles = () => css`
  --align-items: center;
  --gap: ${svar('10')};

  ${mediaQueries.desktopUp} {
    --padding-top: ${svar('40')};
  }

  ${mediaQueries.bigDesktopUp} {
    --padding-top: ${svar('60')};
  }
`
const GatsbyLink = styled(Link)`
  color: ${cvar('accent-brown')};
  text-decoration: underline;
  font-weight: ${fwvar('semibold')};
`

const Text = styled.p`
  text-align: center;
`

const Wrapper = styled.div``
