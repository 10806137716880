import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { cvar, fsvar, mediaQueries } from 'styles'
import { CSSPropertiesWithVars } from '../../types/index'

interface IHeadlineProps extends HTMLAttributes<HTMLHeadingElement> {
  size?: 1 | 2 | 3 | 4 | 5 | 6
  fontSize?: string
  color?: string
  align?: 'left' | 'center' | 'right'
}

export default function Headline({
  size = 1,
  fontSize,
  color,
  align,
  ...rest
}: IHeadlineProps) {
  const Element = [H1, H2, H3, H4, H5, H6][size - 1]
  const tag = `h${size}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

  const styles: CSSPropertiesWithVars = {
    '--font-size': fontSize,
    '--color': color,
    '--text-align': align
  }

  return <Element as={tag} style={styles} {...rest} />
}

const Base = styled.h1`
  --default-font-size: ${fsvar('lg')};
  text-rendering: optimizeLegibility;
  font-size: var(--font-size, var(--default-font-size));
  color: var(--color, ${cvar('text-electric-blue')});
  text-align: var(--text-align, left);
`

const H1 = styled(Base)`
  ${mediaQueries.tabletUp} {
    --default-font-size: ${fsvar('2xl')};
  }

  ${mediaQueries.desktopUp} {
    --default-font-size: ${fsvar('3xl')};
  }
  ${mediaQueries.bigDesktopUp} {
    --default-font-size: ${fsvar('4xl')};
  }
`
export const H2 = styled(Base)`
  ${mediaQueries.tabletUp} {
    --default-font-size: ${fsvar('xl')};
  }
  ${mediaQueries.desktopUp} {
    --default-font-size: ${fsvar('2xl')};
  }
`
const H3 = styled(Base)`
  --default-font-size: ${fsvar('md')};
  ${mediaQueries.desktopUp} {
    --default-font-size: ${fsvar('xl')};
  }
`
const H4 = styled(Base)`
  ${mediaQueries.desktopUp} {
    --default-font-size: ${fsvar('lg')};
  }
`
const H5 = styled(Base)`
  ${mediaQueries.desktopUp} {
    --default-font-size: ${fsvar('md')};
  }
`
const H6 = styled(Base)`
  ${mediaQueries.desktopUp} {
    --default-font-size: ${fsvar('base')};
  }
`
